<!--
 * @Author: your name
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2021-03-15 17:29:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\user\consultation.vue
-->
<template>
  <div>
    <patientSelect @onConfirm="patientConfirm" />
    <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
      <consultation-item :data="dataList" :username="username" />
    </load-more>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
import loadMore from '@/assets/js/mixin/load-more'
export default {
  name:'Consultation',//问诊
  mixins: [loadMore],
  data (){
    return{
      Picker:false,
      patientIdCard:'',
      dataList:[],
      username:''
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.dir('enter')

  //   next(vm => {
  //     if (vm.patientIdCard) {
  //       vm.getList()
  //     }
  //   })
  // },
  methods:{
    getList(){
      let params={
        "zjhm":this.patientIdCard,
        "flag":"3",
        page: this.page,
        pageSize: this.pageSize
      }
      userModel.appointmentList(params).then(res=>{
          this.dataList.push(...(res.data||[]))
          this.total = res.count
          this.loading = false
      })
    },
    patientConfirm(value){
      // console.log('confirm:', value)
      this.patientIdCard=value.zjh||""
      this.username=value.userName
      // console.log('username:', this.username)
      this.page=1
      this.dataList=[]
      this.getList()
    },
     onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }

  }

}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.page-box{
  padding: 0 30px;
}
</style>
